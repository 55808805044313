import {
  appEnvironment,
  appEnvironmentWorkstations,
} from 'src/environments/environment';

export const AppConfig = {
  name: 'Workards',
  version: appEnvironment.currentVersion,
};

export const AppEnv = {
  appCode: appEnvironment.hasOwnProperty('appCode')
    ? appEnvironment.appCode
    : null,
};

export const AppEnvWorkstations = {
  appCode: appEnvironmentWorkstations.hasOwnProperty('appCode')
    ? appEnvironmentWorkstations.appCode
    : null,
};
